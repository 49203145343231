/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

        var $body = $('body');
        var $window = $(window);
        var $matchCard = $body.find('.match-height-js');
        var _windowH = $window.height()-200;

        /* FN: Preloader - on and off
        ----------------------------------------------------*/
        if($body.hasClass('preloader-off'))
        {
          //Reset - Fade Out Transition
          $body.removeClass('page-reload-transition');

          //Site fully Loaded
          $body.addClass("loaded");
        }
        else
        {
          //Reset -Fade Out Transition
          $body.removeClass('page-reload-transition');

          //Window fully loaded
          $window.load(function()
          {
            //Target elements
            var $target_preloader = $("#preloader-logo");

            setTimeout(function()
            {
              $body.addClass("loaded");

              $target_preloader.one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend',function(e)
              {
                $(this).addClass('transition-end');
              });
            },200);
          });//window load
        }//if else


        //Old technique - min height for main content - remove later
        $body.find('.main-content[role="document"]').css('min-height',_windowH+'px');

        /* FN: Header
        ----------------------------------------------------*/
        $('#main-header').headerScrollingFN();
        $('#hamburger').hamburgerFN();
        $body.not('.about').scrollDownHeader();

        /* FN: Single Work Slideshows
        ----------------------------------------------------*/
        $body.find('.work-gallery-slideshow').WorkslideshowGallery();
        $body.find('.work-header-slideshow').WorkHeaderSlideshow();

        /* Filter portfolios
        ----------------------------------------------------*/
        $('#work-filters').PortfolioFilters();
        $('#toggle-filters').toogleMenu();

        /* Google Map
        ----------------------------------------------------*/
        $('#google-map').googleMap();

        /* Header Slideshow
        ----------------------------------------------------*/
        $('#slideshow-hero').headerSlideshow();

        /* Services
        ----------------------------------------------------*/
        $("#services-cards").topCardsScrollTo();

        /* Related Carousel
        ----------------------------------------------------*/
        $("#related-carousel").relatedCarousel();

        /* FN: Match Height
        ----------------------------------------------------*/
        if($matchCard.length)
        {
          $matchCard.matchHeight();
        }
      },
      finalize: function()
      {
        /* body for general use */
        var $body = $('body');

        /* FN: Visible element
        ----------------------------------------------------*/
        $body.find('.visible-el').visibleElementFN();

        /* FN: Touched Bottom
        ----------------------------------------------------*/
        $body.touchBottomFN();

        /* FN: Scroll To element
        ----------------------------------------------------*/
        $body.find('.scroll-to').scrollToElement();

        /* FN: FAQ item
        ----------------------------------------------------*/
        $body.find('.faq-item').faqToggle();

        /* FN: Vimeo autoplay
        ----------------------------------------------------*/
        $body.find('.video-overlay').playVideo();

        /* FN: News
        ----------------------------------------------------*/
        $('#category-filters').newsFilters();

        /* FN: Testimonials
        ----------------------------------------------------*/
        $('#testimonials-slider').testimonialsCarousel();
        $('.testimonials-mobile-wrap').testimonialsLoadMore();

        /* FN: Brain Picker
        ----------------------------------------------------*/
        $body.find('.brain-picker-carousel').brainPickerEvents();
        $body.find('.brain-picker-carousel').brainPickerCarousel();

        /* FN: Packages
        ----------------------------------------------------*/
        $body.find('.packages-section').packagesFN();

        /************** FN: Page Transition *****************
        ----------------------------------------------------*/
        var $js_transition = $('#mobile-menu-wrap a, #menu-footer-nav a, #main-header .eggbeater-logo, #main-header .menu a,.js-transition:not([href^="#"],[href*="wp-login"],[href*="wp-admin"])');

        $js_transition.each(function()
        {
          $(this).on('click', {},function(e)
          {
            //Transitions init - Fade out
            $body.addClass("page-reload-transition");
          });
        });
      }
    },
    'quote': {
      init: function()
      {
        /* Quote form - JS
        -----------------------------------------------------*/
        $('#gform-container').quoteFormInit();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);


/*-----------------------------------------------------------------------------------------
  FN: Packages
-----------------------------------------------------------------------------------------*/
$.fn.packagesFN = function()
{
  var $target = $(this);

  if($target.length)
  {
    var $packageCards = $target.find('.card-wrap');
    var $packagesWrapper = $target.find('.wrap-packages-content');
    var $packagesContent = $target.find('.package-wrap');

    $packageCards.click(function(e)
    {
      e.preventDefault();

      var _target = $(this).attr('data-target');

      //Active
      $packageCards.removeClass('active');
      $packagesContent.removeClass('active');

      $(this).addClass('active');
      $(_target).addClass('active');

      if (_target.length && _target.indexOf("#") > -1)
      {
        //Height
        $packagesWrapper.css('height', $(_target).height());

        //Scroll to package
        $(_target).scrollToSection(75);

        //Resize
        $(window).resize(function()
        {
          $packagesWrapper.css('height', $(_target).height());
        });
      }
      else
      {
        alert('Package not found');
      }

      return false;
    });

  }
};



/*-----------------------------------------------------------------------------------------
  FN: Related Carousel
-----------------------------------------------------------------------------------------*/
$.fn.relatedCarousel = function()
{
  var $target = $(this);

  if($target.length)
  {
    var $prev = $target.find(".arrow-prev"),
        $next = $target.find(".arrow-next");

    if($(window).width() > 640)
    {
      $target.slick({
       slidesToShow: 3,
       slidesToScroll: 3,
       rows: 0,
       slide: ".slide-item",
       autoplay: false,
       arrows: true,
       dots: false,
       fade: false,
       adaptiveHeight: false,
       prevArrow:$prev,
       nextArrow:$next,
       infinite: true,
       responsive: [
        {
          breakpoint: 790,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 542,
          settings: {
            unslick: true
          }
        }
      ]
     });
    }
  }
};




/*-----------------------------------------------------------------------------------------
  FN: Testimonials
-----------------------------------------------------------------------------------------*/
$.fn.testimonialsCarousel = function()
{
  var $target = $(this);

  if($target.length)
  {
    var $prev = $target.find(".arrow-prev"),
        $next = $target.find(".arrow-next");

    $target.slick({
     slidesToShow: 2,
     slidesToScroll: 2,
     rows: 0,
     slide: ".slide-item",
     autoplay: false,
     arrows: true,
     dots: false,
     fade: false,
     adaptiveHeight: true,
     prevArrow:$prev,
     nextArrow:$next,
     infinite: true,
     responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }]
   });
  }
};


/*-----------------------------------------------------------------------------------------
  FN: Testimonials Load More
-----------------------------------------------------------------------------------------*/
$.fn.testimonialsLoadMore = function()
{
  var $target = this;

  if($target.length)
  {
    var $last_visible;

    var _last_item_id = $target.find('.testimonial-item:last').attr('data-id');

    $target.find('.load-more').click(function(e)
    {
      e.preventDefault();

      var $last_visible = $target.find('.testimonial-item:visible:last');

      $target.addClass('active');

      setTimeout(function()
      {
        $target.removeClass('active');

        $last_visible.next().eq(0).addClass('show-item');
        $last_visible.next().next().eq(0).addClass('show-item');

        if(_last_item_id===$target.find('.testimonial-item:visible:last').attr('data-id'))
        {
          $target.find('.load-more').fadeOut();
        }
      },600);

      return false;
    });
  }
};


/*-----------------------------------------------------------------------------------------
  FN: Slideshow
-----------------------------------------------------------------------------------------*/
$.fn.headerSlideshow = function()
{
  var $target = this;

  if($target.length)
  {
    var $prev = $target.find(".arrow-prev"),
        $next = $target.find(".arrow-next");

    $(window).load(function()
    {
      $target.addClass("loaded");

      $target.slick({
       slidesToShow: 1,
       slidesToScroll: 1,
       rows: 0,
       slide: ".slide-item",
       autoplay: true,
       autoplaySpeed: 6000,
       speed: 800,
       arrows: true,
       dots: false,
       fade: false,
       adaptiveHeight:true,
       prevArrow:$prev,
       nextArrow:$next,
       infinite: true
     });
    });
  }
};


/*------------------------------------------------------------------------------------
    QUOTE form
-------------------------------------------------------------------------------------*/
$.fn.quoteFormInit = function()
{
  var $target = $(this);

  if($target.length)
  {
    var _class, _strClass;

    //1. Generate class based on field description
    $target.find('.gform_fields > li').each(function(i)
    {
      $(this).attr('data-count',i);

      // Gform Field Desription - category
      if($('.gfield_description',this).length)
      {
        var _class = $('.gfield_description:not(.validation_message)',this).text().toLowerCase();
        var _strClass = _class.replace(/ /g,"-");
        var _fieldClass = _strClass.replace(/\./g,"");

        if(_class.length)
        {
          $(this).addClass(_fieldClass).attr('data-section',_fieldClass);
        }
      }

      // Gform Field Label title - replace {}
      if($('.gfield_label:not(.replaced-on):contains({), .gfield_label:not(.replaced-on):contains(})', this).length)
      {
        var label_text = $('label.gfield_label',this).html().replace(/{/g,'<strong>').replace(/}/g,'</strong>');

        $('.gfield_label', this).html(label_text);

        $('label.gfield_label',this).addClass('replaced-on');
      }


      // Gform HTML .form-section - Title, image, description
      if($('.form-section',this).length)
      {
        var _section_class = $('h2,h3,h4',this).attr('data-id').toLowerCase();
        var _sectionstr = _section_class.replace(/ /g,"-");
        var _html_section = _sectionstr.replace(/\./g,"");

        if(_html_section.length)
        {
          $(this).addClass(_html_section);
        }
      }

    }).promise().done(function()
    {
      var $form_wrapper = $("#gform-container");

      // 2. Callback
      $("#step-1").topCardsScrollTo();
      $("#step-2").quoteFormStep_2();
      $("#custom-submit").quoteCustomSubmit();

      $form_wrapper.quoteFormAddButtons();
      $form_wrapper.validateForm();
    });
  }
};



/*------------------------------------------------------------------------------------
    Validation
-------------------------------------------------------------------------------------*/
$.fn.validateForm = function()
{
  var $target = $(this);

  if($target.length)
  {
    var $form = $target.find('form');

    if($form.length)
    {
      $form.find('.gfield_contains_required').each(function()
      {
        if($('input,textarea',this).attr('name'))
        {
          $('input,textarea',this).addClass('required');
        }
      }).promise().done(function()
      {
        //jQuery validate plugin
        /*
        if($.isFunction($.fn.validate))
        {
          $form.each(function()
          {
            $(this).validate();

            $(this).addClass('validation-on');
          });
        }
        */
      });
    }

    //Gravity Forms Validation Message
    if($target.find('.validation_message').length && $('.gform_wrapper').hasClass('gform_validation_error'))
    {
      $target.addClass('active');

      //Show specific error
      /*$('.validation_message').each(function()
      {
        $(this).parents('.gfield').eq(0).show();
      });*/

      //Show all fields
      $('li.gfield').show();

      $(window).load(function()
      {
        $('.ginput_container_fileupload').parents('.gfield').eq(0).hide();

        $('.gform_fields .gfield_error').show();

        var _pos = $('.gform_fields .gfield_error:first').eq(0).offset().top;

        $('html, body').animate({
          scrollTop: _pos
        }, 900);
      });
    }
  }
};


/*------------------------------------------------------------------------------------
    STEP 1 - QUOTE
-------------------------------------------------------------------------------------*/
$.fn.topCardsScrollTo = function()
{
  var $target = $(this);

  if($target.length)
  {
    var $step1_wrapper = $target.find('.button-wrap');

    $step1_wrapper.on('click','a',function(e)
    {
      e.preventDefault();

      if($('.gform_confirmation_wrapper').length)
      {
         location.reload();
      }

      //Vars
      var _target = $(this).attr('href');

      //$target.find('a').removeClass('active');
      //$(this).addClass('active');

      if (_target.length && _target.indexOf("#") > -1)
      {
        //Go to step 2
        $(_target).show();

        setTimeout(function(){
          $(_target).scrollToSection(60);
        },40);

      }
      else
      {
        //Redirect
        window.location = _target;
      }

      return false;
    });

  }
};


/*------------------------------------------------------------------------------------
    STEP 2 - QUOTE
-------------------------------------------------------------------------------------*/
$.fn.quoteFormStep_2 = function()
{
  var $target = $(this);

  if($target.length)
  {
    var $target_section,_target,_targetClass;
    var $form = $('#gform-container');
    var $step2_wrapper = $target.find('.button-wrap');
    var $add_buttons = $form.find('.add-buttons');

    $step2_wrapper.on('click','a',function(e)
    {
      e.preventDefault();

      _target = $(this).attr('data-target');
      _targetClass = '.'+_target;

      if(_target.length)
      {
        $target_section = $form.find(_targetClass);

        if($(this).hasClass('active'))
        {
          $target_section.hide();
          $(this).removeClass('active');
          $add_buttons.find('a[data-target="'+_target+'"]').show();
        }
        else
        {
          $target_section.show();
          $(this).addClass('active');
          $add_buttons.find('a[data-target="'+_target+'"]').hide();

          //Go to first section
          setTimeout(function(){
            $target_section.eq(0).scrollToSection(0);
          },40);
        }
      }

      //Add class to form wrapper
      if($step2_wrapper.find('.active').length)
      {
        $form.addClass('active');
      } else {
        $form.removeClass('active');
      }

      return false;
    });
  }
};


/*------------------------------------------------------------------------------------
    Add Buttons
-------------------------------------------------------------------------------------*/
$.fn.quoteFormAddButtons = function()
{
  var $target = $(this);

  if($target.length)
  {
    var $target_section,_target,_targetClass;
    var $add_buttons = $target.find('.add-buttons');
    var $step2_wrapper = $("#step-2").find('.button-wrap');

    $add_buttons.find('a').click(function(e)
    {
      e.preventDefault();

      _target = $(this).attr('data-target');
      _targetClass = '.'+_target;

      if(_target.length)
      {
        $target_section = $target.find(_targetClass);

        $step2_wrapper.find('a[data-target="'+_target+'"]:not(.active)').addClass('active');
        $add_buttons.find('a[data-target="'+_target+'"]').hide();
        $target_section.show();

        //Go to first section
        setTimeout(function(){
          $target_section.eq(0).scrollToSection(0);
        },40);
      }

      return false;
    });
  }
};


/*------------------------------------------------------------------------------------
    Form Submit
-------------------------------------------------------------------------------------*/
$.fn.quoteCustomSubmit = function()
{
  var $target = $(this);

  var $form = $('#gform-container');

  if($target.length)
  {
    var $custom_submit = $target.find('a');
    var $gform_button = $form.find('.gform_button');

    $custom_submit.text($gform_button.val());

    $custom_submit.click(function(e)
    {
      e.preventDefault();

      //SUBMIT FORM
      if($form.find('input[type="submit"]').length)
      {
        $form.find('input[type="submit"]').click();
      } else {
        alert('ERROR! Please contact us via email or phone.');
      }

      return false;
    });
  }
  else
  {
    $form.find('.gform_footer').show();
  }
};


/*------------------------------------------------------------------------------------
    Section
-------------------------------------------------------------------------------------*/
$.fn.scrollToSection = function(_offset)
{
  var $target = $(this);

  if($target.length)
  {
    var _offset_top = (_offset)?_offset:0;
    var _pos = $target.offset().top-_offset_top;

    $('html, body').animate({
      scrollTop:_pos
    }, 900);

  } else { alert('Section not found'); }
};


/*-------------------------------------------------------------------------------
    Portfolio Isotope
-------------------------------------------------------------------------------*/
$.fn.PortfolioFilters = function()
{
  var $target = $(this);

  if($target.length)
  {
    var $grid = $('.grid');
    var _work_hashtag = location.hash.substr(1);
    var _str;


    //ISOTOPE
    if($.isFunction($.fn.isotope))
    {
      $grid.isotope({
        itemSelector: '.portfolio-item'
      });
    }

    //Click filtering
    $target.on( 'click', 'a', function(e)
    {
      e.preventDefault();

      if(!$(this).hasClass('active'))
      {
        var filterValue = $(this).attr('href');
        var _str = (filterValue.length && filterValue.indexOf("#") > -1)? filterValue.split('#')[1]:filterValue;
        var filterClass = '.'+_str;

        $target.find('a').removeClass('active');

        $(this).addClass('active');

        if(_str.length)
        {
          if($.isFunction($.fn.isotope))
          {
            $grid.isotope({
              filter:filterClass
            });
          }
        }
        else
        {
          alert('No filter found.');
        }
      }

      return false;
    });

    //ON LOAD FN - active
    if (_work_hashtag.length)
    {
      var $filter_link = $target.find('a[href="#'+_work_hashtag+'"]');

      if($filter_link.length)
      {
        $filter_link.click();
      } else {
        alert("We couldn't find filter: "+_work_hashtag);
      }
    }
  }
};


/*-------------------------------------------------------------------------------
    Toggle menu
-------------------------------------------------------------------------------*/
$.fn.toogleMenu = function()
{
  var $target = $(this);

  if($target.length)
  {
    var $filters_wrap = $('.wrap-filters');

    //MOBILE TOGGLE Button
    $target.click(function(e)
    {
      e.preventDefault();

      if($(this).hasClass('active'))
      {
        $(this).removeClass('active');
        $filters_wrap.slideUp();
      }
      else
      {
        $(this).addClass('active');
        $filters_wrap.slideDown();
      }

      return false;
    });
  }
};


/*-------------------------------------------------------------------------------
    Single Work Header
-------------------------------------------------------------------------------*/
$.fn.WorkHeaderSlideshow = function()
{
  var $target = $(this);

  if($target.length)
  {
    var $prev = $target.find(".arrow-prev"),
        $next = $target.find(".arrow-next");

    $target.slick({
      dots: false,
      arrows: true,
      infinite: true,
      rows: 0,
      slide: ".item",
      speed: 1600,
      slidesToShow: 1,
      fade: true,
      autoplay: true,
      autoplaySpeed: 3000,
      prevArrow:$prev,
      nextArrow:$next
    });
  }
};


/*-------------------------------------------------------------------------------
    Single Work Slideshow Portfolio
-------------------------------------------------------------------------------*/
$.fn.WorkslideshowGallery = function()
{
  var $target = $(this);

  if($target.length)
  {
    $target.each(function()
    {
      var $prev = $('.prev-arrow',this);
      var $next = $('.next-arrow',this);

      $(this).slick({
        arrows: true,
        infinite: true,
        prevArrow: $prev,
        nextArrow: $next,
        rows: 0,
        slide: ".slide-item",
        speed: 300,
        slidesToShow: 1,
        fade: true,
        dots: false
      });
    });//each
  }
};


/*-------------------------------------------------------------------------------
    Video
-------------------------------------------------------------------------------*/
$.fn.playVideo = function()
{
  var $target = $(this);

  if($target.length)
  {
    $target.click(function(e)
    {
      e.preventDefault();

      $target.remove();

      $(".eggbeater-video iframe")[0].src += "&autoplay=1";

      return false;
    });
  }
};


/*-------------------------------------------------------------------------------
    FAQ - Item
-------------------------------------------------------------------------------*/
$.fn.faqToggle = function()
{
  var $target = $(this);

  if($target.length)
  {
    var $question = $target.find(".faq-question");

    $question.each(function()
    {
      $(this).click(function(e)
      {
        e.preventDefault();

        var $this_wrapper = $(this).parents(".faq-item").eq(0);

        if(!$this_wrapper.hasClass('active'))
        {
          $(".accordion-wrapper .active").find('.faq-answer').slideUp(360);
          $(".accordion-wrapper .active").removeClass('active');

          $this_wrapper.find(".faq-answer").slideDown(380);
          $this_wrapper.addClass("active");

        } else {
          $this_wrapper.find(".faq-answer").slideUp(380);
          $this_wrapper.removeClass("active");
        }

        setTimeout(function()
        {
          var _offset = 70;

          $this_wrapper.scrollToSection(_offset);
        },440);

        return false;
      });
    });
  }
};


/*-------------------------------------------------------------------------------
    Filters
-------------------------------------------------------------------------------*/
$.fn.newsFilters = function()
{
  var $target = $(this);

  if($target.length)
  {

    $target.find(".filter-btn").click(function(e)
    {
      e.preventDefault();

      var $wrapper = $target.find(".toggle-this");

      if($(this).hasClass("active"))
      {
        $(this).removeClass("active");
        $wrapper.slideUp();
      } else {
        $(this).addClass("active");
        $wrapper.slideDown();
      }

      return false;
    });
  }
};



/*-------------------------------------------------------------------------------
    Scrolling
-------------------------------------------------------------------------------*/
$.fn.headerScrollingFN = function()
{
  var $target = $(this);

  if($target.length)
  {
    var _height = $target.height()+220,
        $body = $("body"),
        $window = $(window),
        $pos = "";

    $window.scroll(function()
    {
       $pos = $window.scrollTop();

       if($pos>=_height)
       {
         $body.addClass("scrolling-init");
       } else {
         $body.removeClass("scrolling-init");
       }
    });
  }
};


/*-------------------------------------------------------------------------------
    Scroll To
-------------------------------------------------------------------------------*/
$.fn.scrollToElement = function()
{
  var $target = $(this);

  if($target.length)
  {
    $('.scroll-to:not(.scroll-to-on)').each(function()
  	{
  		$(this).click(function(e)
  		{
  			e.preventDefault();

        var $this = $(this);
  			var id = ($this.attr('data-target'))?$this.attr('data-target'):$this.attr('href');
  			var offset = ($this.data('offset'))? parseInt($this.data('offset')) : 0;
  			var speed = ($this.data('speed'))? parseInt($this.data('speed')) : 800;
  			var top = $(id).offset().top + offset;

  			// animation
  			if(!$('html:animated, body:animated').length)
        {
          $('html,body').animate({scrollTop: top},speed);
        }
  		});

  		$(this).addClass('scroll-to-on');
  	});
  }
};


/*-------------------------------------------------------------------------------
    Hamburger
-------------------------------------------------------------------------------*/
$.fn.hamburgerFN = function()
{
  var $target = $(this);

  if($target.length)
  {
    $target.find("a").click(function(e)
    {
      e.preventDefault();

      var $this = $(this),
          $menu = $("#mobile-menu-wrap"),
          $body = $("body");

      if($this.hasClass("active"))
      {
        //Close
        $this.removeClass("active");
        $menu.removeClass("opened");
        $body.removeClass("menu-opened");
      }
      else
      {
        $this.addClass("active");
        $menu.addClass("opened");
        $body.addClass("menu-opened");
      }

      return false;
    });
  }
};


/*-------------------------------------------------------------------------------
    Visible Element
-------------------------------------------------------------------------------*/
$.fn.visibleElementFN = function()
{
  var $target = $(this);

  if($target.length)
  {
    $(window).scroll(function()
		{
		  $target.each(function(i, el)
		  {
		    var _el = $(el);

		    if (_el.onViewportFN(true))
		    {
		      _el.addClass("element-visible");
		    }
		  });
		});
  }
};


/*-------------------------------------------------------------------------------
    On Viewport Element - Visible
-------------------------------------------------------------------------------*/
$.fn.onViewportFN = function(partial)
{
  var $t            = $(this),
      $w            = $(window),
      viewTop       = $w.scrollTop(),
      viewBottom    = viewTop + $w.height(),
      _top          = $t.offset().top,
      _bottom       = _top + $t.height(),
      compareTop    = partial === true ? _bottom : _top,
      compareBottom = partial === true ? _top : _bottom;

return ((compareBottom <= viewBottom) && (compareTop >= viewTop));
};


/*-------------------------------------------------------------------------------
    Touched Bottom
-------------------------------------------------------------------------------*/
$.fn.touchBottomFN = function()
{
  //Target body
  var $target = $(this);

  if($target.length)
  {
    var $window = $(window), $document = $(document);

    $window.scroll(function()
		{
			var beforeBottom = 240,
          _height = $document.height()-beforeBottom,
          w_height = $window.height(),
          total = $window.scrollTop()+w_height;

       if(_height<total)
       {
         $target.addClass("touched-bottom");
       } else {
	       $target.removeClass("touched-bottom");
       }
	   });
  }
};


/*-------------------------------------------------------------------------------
    Scroll Down Header Hide
-------------------------------------------------------------------------------*/
$.fn.scrollDownHeader = function()
{
  //Target body
  var $target = $(this);

  if($target.length)
  {
    var $window = $(window);
    var $headerToHide = $('#main-header');


    //Handle scrolling if bigger than 1000
    if($window.width() > 320)
  	{
      //Reset vars
      var lastScrollTop       = 0,
          initTop             = 0,
          changeDirection     = false,
          gutterOffset        = 300,
          lastDirectionDown   = false;

      //Height
      var headerHeight = $headerToHide.height();

      $window.scroll(function ()
      {
        if(!$('body').hasClass('menu-opened'))
        {
          var thisScrollTop = $(this).scrollTop();
              changeDirection = ( thisScrollTop > gutterOffset && (thisScrollTop > lastScrollTop && lastDirectionDown === false) || (thisScrollTop < lastScrollTop && lastDirectionDown === true) );

          if (changeDirection === true)
          {
            //Class for css if needed
            $headerToHide.toggleClass('scrolling-header');

            lastDirectionDown = ( lastDirectionDown === false );
          }

          $headerToHide.css( {
            'top': $headerToHide.hasClass('scrolling-header') ? (-1) * headerHeight : initTop
          });

          lastScrollTop = thisScrollTop;
        }
      });
    }
  }
};


/*------------------------------------------------------------------------------------
    Declare FN: Google map - JS only for ** MULTIPLE MARKERS **
-------------------------------------------------------------------------------------*/
$.fn.googleMap = function()
{
  //Get elements and content
  var $map = $(this);
  var snazzymaps_array = [{"featureType":"landscape","elementType":"all","stylers":[{"hue":"#FFBB00"},{"saturation":43.400000000000006},{"lightness":37.599999999999994},{"gamma":1}]},{"featureType":"poi","elementType":"all","stylers":[{"hue":"#00FF6A"},{"saturation":-1.0989010989011234},{"lightness":11.200000000000017},{"gamma":1}]},{"featureType":"poi.business","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi.park","elementType":"all","stylers":[{"color":"#bee5ad"}]},{"featureType":"road.highway","elementType":"all","stylers":[{"hue":"#FFC200"},{"saturation":-61.8},{"lightness":45.599999999999994},{"gamma":1}]},{"featureType":"road.arterial","elementType":"all","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":51.19999999999999},{"gamma":1}]},{"featureType":"road.local","elementType":"all","stylers":[{"hue":"#FF0300"},{"saturation":-100},{"lightness":52},{"gamma":1}]},{"featureType":"water","elementType":"all","stylers":[{"saturation":-13.200000000000003},{"lightness":2.4000000000000057},{"gamma":1},{"color":"#a4daf1"}]}];

  if($map.length)
  {
    //Defaults
    var $target = $map.find(".marker"),
        infowindow = null,
        html_content = null,
        _zoom = parseInt($map.attr('data-zoom'));

    if($target.length)
    {
      var _address = $target.attr("data-address");
      var centerLat = $map.attr("data-lat") || parseInt(49.2576508);
      var centerLng = $map.attr("data-lng") || parseInt(-123.2639868);

      if(_address.length)
      {
        // Map options
        var options = {
            center: new google.maps.LatLng(centerLat,centerLng),
            scrollwheel : false,
            zoom: _zoom,
            styles: snazzymaps_array
          },
          map = new google.maps.Map(document.getElementById('map'), options);

        $target.each(function(i)
        {
          var $this = $(this),
              _icon = ($this.attr("data-icon").length)?$this.attr("data-icon"):null,
              _address = $this.attr("data-address"),
              _lat = $this.attr("data-lat"),
              _lng = $this.attr("data-lng"),
              _content = $this.html(),
              latlng = new google.maps.LatLng(_lat, _lng),
              marker = new google.maps.Marker({
                 position: latlng,
                 map: map,
                 title: _address,
                 icon: _icon
              }),
              infowindow = new google.maps.InfoWindow({
                content: _content
              });

            //Info window listener
            if(_content.length)
            {
              marker.addListener('click', function()
              {
                infowindow.open(map, marker);
              });
            }
        });

      } else { alert("No address found"); } //END if(_address.length)

    }//END if($target.length)
  }//END if($map.length)

}; /*END googleMap FN */



/*------------------------------------------------------------------------------------
    RYAN - Brain Picker
-------------------------------------------------------------------------------------*/

//1. Ryan - BP Event
$.fn.brainPickerEvents = function()
{
  //Target body
  var $target = $(this);

  if($target.length)
  {
     var postval;

     /*
     if($.cookie("brainpicker_active")==="1")
     {
       var _get_cookie_id, $target_items;

       $target.find('.wrap-id').each(function()
       {
         _get_cookie_id = $.cookie("brainpicker_"+$(this).attr('data-id'));

         $target_items = $('.brain-item[data-postid="'+_get_cookie_id+'"]');

         $target_items.addClass('active');
         $target_items.find('.confirm').addClass('clicked');
       });
     }*/

     //CONFIRM
     $target.find('.confirm:not(.clicked)').click(function(e)
     {
       e.preventDefault();

       if(!$(this).hasClass('clicked'))
       {
         var _id = $(this).attr('data-postid');
         var _clicked_item = $(this).parents('.brain-picker').eq(0);

         /*
         $.cookie('brainpicker_active', '1', { expires: 1, path: '/' });
         $.cookie('brainpicker_'+_id, _id, { expires: 1, path: '/' });
         */

         /* IMPORTANT updates the item vote + one vote */
         postval = $(this).data('val') + 1;

         $(this).attr('data-val', postval).addClass('clicked');

         //Loading
         _clicked_item.addClass('loading');

         //CALLBACK
         $(this).brain_picker(_id, $(this).attr('data-position'), postval, _clicked_item);
       }

       return false;
    });

    //No
    $target.find('.no').click(function(e)
    {
      e.preventDefault();

      var $brain_picker_item = $(this).parents('.brain-item').eq(0);

      $brain_picker_item.removeClass('menu-active');

      //CSS Hack for mobile
      $brain_picker_item.addClass('remove-hovered-menu');

      setTimeout(function()
      {
        //CSS hack
        $brain_picker_item.removeClass('remove-hovered-menu');
      },2000);

      return false;
   });

   //Choose menu items
   $target.find('.brain-heart-hover:not(.active)').click(function(e)
   {
     e.preventDefault();

     $(this).parents('.brain-item').eq(0).addClass('menu-active');
     $(this).addClass('active');

     return false;
  });
  }
};


/* 2. Ryan - brain picker ajax function *clicker* */
$.fn.brain_picker = function(_postid, _position, _postval, _clicked_item)
{
  if(_postid.length)
  {
    var $brain_picker_carousel = $('.brain-picker-carousel');
    var $brain_picker_wrapper = $('.brain-picker-wrap');
    var $carousel_active_row = $brain_picker_carousel.find('.row[data-id = '+ _postid +']');

    jQuery.ajax({
      url : ajax_url,
      type : 'post',
      data : {
        action : 'brain_picker_update',
        postid: _postid,
        position: _position,
        postval: _postval,
      },
      success : function( response )
      {
        /* calculate total of clicks to find percentage */
        var total = 0;

        /* puts values into array and finds the largest which decides the winner */
        var highestx = [];

        //Show bottom nav - gimme another one
        if(!$brain_picker_wrapper.hasClass('chosen-active'))
        {
          $brain_picker_wrapper.addClass('chosen-active');
        }

        //Active
        $carousel_active_row.addClass('voted-row');
        $carousel_active_row.find('.brain-item').addClass('active');

        /* disable being clicked on again */
        $carousel_active_row.find('.brain-item').removeClass('menu-active');

        //Clicked Item to add heart
        _clicked_item.addClass('myPick');

        //Remove Loader
        _clicked_item.addClass('loading');

        //Confirm button
        $carousel_active_row.find('.confirm').each(function()
        {
          if( $(this).attr('data-postid') === _postid )
          {
            total = total + parseInt($(this).attr('data-val'));
          }
        });

        //Push array
        $carousel_active_row.find('.confirm').each(function()
        {
          var percent_amount = parseInt($(this).attr('data-val'));

          highestx.push(percent_amount);

        }).promise().done(function()
        {
          //sort array highest to lowest
          highestx.sort(function(a, b)
          {
            return b-a;
          });
        });

        /* Calculates the percentage of each and appends the brain col to show the percentage */
        $carousel_active_row.find('.confirm').each(function()
        {
          if( $(this).attr('data-postid') === _postid )
          {
            var amount = $(this).attr('data-val');
            var percentage = Math.round((amount / total) * 100);
            var $item_wrap = $(this).parents('.brain-item').eq(0);

            $item_wrap.find('.result-first-row > .percent').html(percentage+'%');
          }
        });

        /* Compares winner value to each value and adds the winner class */
        $carousel_active_row.find('.confirm').each(function()
        {
          var winner = parseInt( $(this).attr('data-val') );

          if( winner ===  highestx[0] )
          {
            $(this).parents('.brain-item').eq(0).addClass('winner');
          }
        }).promise().done(function()
        {
          /* More than one winners */
          var _count_winners = $brain_picker_carousel.find('.winner[data-postid = '+ _postid +']').length;

          if(_count_winners > 1)
          {
            $carousel_active_row.addClass('more-than-one-winner');
          }
        });

      },
      error: function(jqXHR, textStatus, errorThrown)
      {
        alert('Oops, there was an error: '+ textStatus + errorThrown +jqXHR.status);
      }
    });
  } else {
    alert('There was an error: No post_id found.');
  }
};



/*-----------------------------------------------------------------------------------------
  FN: Brain Picker Carousel
-----------------------------------------------------------------------------------------*/
$.fn.brainPickerCarousel = function()
{
  var $target = $(this);

  if($target.length)
  {
    var $prev = $target.find(".arrow-prev"),
        $next = $target.find(".arrow-next"),
        $next_slide_btn = $("#next-brain-slide");

    $target.slick({
     slidesToShow: 1,
     slidesToScroll: 1,
     rows: 0,
     slide: ".wrap-slides",
     autoplay: false,
     arrows: true,
     dots: false,
     fade: false,
     adaptiveHeight: false,
     prevArrow: $prev,
     nextArrow: $next,
     infinite: false,
     responsive: [
     {
       breakpoint: 771,
       settings: {
        fade: true
       }
     }]
   });

   //Header
   $next_slide_btn.click(function(e)
   {
     e.preventDefault();

     $next.click();

     return false;
   });

   if($('body').hasClass('single-brain_picker_cpt'))
   {
     var _activePost = $('.brain-picker-carousel').attr('data-active');
     var _activeSlide = $('.brain-picker-carousel').find('.wrap-slides[data-active="'+_activePost+'"]').attr('data-counter');

     $target.slick('slickGoTo', _activeSlide - 1);
   }


   //Less than 782 animate to top
   if($(window).width() <= 782)
   {
     $target.on('beforeChange', function(event, slick, currentSlide, nextSlide)
     {
       $('html, body').animate({
         scrollTop: $target.offset().top-60
       }, 600);
     });
   }
  }
};

})(jQuery); // Fully reference jQuery after this point.
